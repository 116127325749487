<template>
    <div class="m2 wrapper wrapper--default">
        <h3 class="text-center m-2">Soorten - {{ bu }}</h3>
        <hr>
        <Loading v-if="loading" />
        <div class="p-2" v-else>
            <div class="flex center">
                <PurchaseDuoTable :table_data="kinds" :table_data_totals="totals" :bu="bu" />
            </div>
        </div>
    </div>
</template>
<script>
import PurchaseDuoTable from '@/components/PurchaseDuoComponents/PurchaseDuoTable.vue';
import Loading from '@/components/Loading.vue';
import request from '@/functions/request.js';

export default {
    props: ['bu'],
    components: { PurchaseDuoTable, Loading },
    data: () => ({
        kinds: null,
        loading: true
    }),
    created() {
        this.getData(this.bu)
    },
    watch: {
        bu(newbu) {
            this.loading = true;
            this.getData(newbu)
        }
    },
    methods: {
        getData(bu) {
            request(`purchaseduo/soort/${bu}`, "GET").then(({ kinds, totals }) => {
                this.totals = totals;
                this.kinds = kinds;
                this.loading = false;
            })
        }
    }
};
</script>